import logoLink from '../resources/stwbo-netz.png';
import companyLogoLink from '../resources/stwbo-netz.svg';
import footerLogoLink from '../resources/stwbo-netz_emeye-t-inside_logo.svg';

export const cssVariables = {
    header_footer_seperator: '#E3000F',
    panel_highlight: '#919292',
    chat_background_right: '#F7B7BC',
    chat_background_left: '#C8C9C9',

    active: '#404040',
    inactive: '#FF8080',
    text_on_active: '#fff',
    text_on_inactive: '#fff',

    dropdown_background: '#F7B7BC',

    phonebook_dropdown: '#F7B7BC',
    phonebook_dropdown_svg: '#000',

    primary_light: '#F7B7BC',
    primary: '#404040',
    text_on_primary: '#fff',
    text_on_primary_caller: 'rgb(255, 255, 255)',

    secondary: '#E3000F',
    text_on_secondary: '#fff',
    focus_active:'#E3000F',

    red: '#E3000F',
    green: '#3AC13A',

    connecting: '#C8C9C9',
    connected: '#3AC13A',
    aborted: '#E3000F',

    font_color: 'black',
    link_color: '#404040',

    font: 'Arial, Helvetica, sans-serif',
    font_size: '16px',
    font_weight: '400',

    border_radius: '20px',
    border_width: '2px',
    border_style: 'solid',
    border_radius_narrow: '10px',
    border_radius_super_narrow: '5px',
    border_width_nav: '8px',

    disabled: '#C8C9C9',

    gridbox_header_bg: '#919292',
    gridbox_header_text: '#fff',
    gridbox_padding: '8px',
    gridbox_header_padding: '8px',

    pointer: '#E3000F',

    toggle_switch: 'rgb(255, 255, 255)',
};

export const logo = logoLink;
export const companyTitle = 'Stadtwerke Bochum Netz OnHelp';
export const companyLogo = companyLogoLink;
export const footerLogo = footerLogoLink;
